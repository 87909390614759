import React from "react";
import DropdownSelect from "../dropdown/Dropdown";
import ValidationText from "../../utils/validation/ValidationText";

function FormSettingPageManagement(props) {
    return (
        <div className="flex gap-4 mb-9 mt-9">
            <div>
                <div className="flex items-center mt-9">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1 w-[170px]">
                        First Name
                    </h2>
                    <div className="w-[350px] px-9">
                        <DropdownSelect
                            drpIdentity={"firstNameAvailabilityId"}
                            optionArray={props.availabilityArray}
                            setFilterParameters={props.setFilterParameters}
                            value={props.formAvailability.firstNameAvailabilityId}
                        />
                        <ValidationText error={props.validState.error.firstNameAvailabilityId} />
                    </div>
                </div>
                <div className="flex items-center mt-9">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1 w-[170px]">
                        Email
                    </h2>
                    <div className="w-[350px] px-9">
                        <DropdownSelect
                            drpIdentity={"emailAvailabilityId"}
                            optionArray={props.availabilityArray}
                            setFilterParameters={props.setFilterParameters}
                            value={props.formAvailability.emailAvailabilityId}
                            disabled={true}
                        />
                        <ValidationText error={props.validState.error.emailAvailabilityId} />
                    </div>
                </div>
                <div className="flex items-center mt-9">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1 w-[170px]">
                        Company Name
                    </h2>
                    <div className="w-[350px] px-9">
                        <DropdownSelect
                            drpIdentity={"companyAvailabilityId"}
                            optionArray={props.availabilityArray}
                            setFilterParameters={props.setFilterParameters}
                            value={props.formAvailability.companyAvailabilityId}
                        />
                        <ValidationText error={props.validState.error.companyAvailabilityId} />
                    </div>
                </div>
                <div className="flex items-center mt-9">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1 w-[170px]">
                        Designation
                    </h2>
                    <div className="w-[350px] px-9">
                        <DropdownSelect
                            drpIdentity={"designationAvailabilityId"}
                            optionArray={props.availabilityArray}
                            setFilterParameters={props.setFilterParameters}
                            value={props.formAvailability.designationAvailabilityId}
                        />
                        <ValidationText error={props.validState.error.designationAvailabilityId} />
                    </div>
                </div>
                <div className="m-3">
                      <label className="flex items-center">
                        <input
                          type="radio"
                          name="radio-buttons"
                          id="isUpload"
                          className="form-radio"
                          value={props.formAvailability.isUpload}
                          onChange={(event) => props.handleRadioButtons(event)}
                          checked={props.formAvailability.isUpload === true}
                        />
                       
                        <span className="text-xl  font-medium theme-color ml-2">
                        PDF Upload
                        </span>
                      </label>
                    </div>
                    {props.formAvailability.isUpload === true ? (
                <div className="flex items-center mt-9 url-part">
                    <span className="text-xl  font-medium theme-color mr-5">
                            PDF Upload
                          </span>
                    <div className="flex item-center flex-col w-[350px] px-9">
                        <form className="custom-uploader border flex items-center border-[#757575] text-center px-14 py-3">
                            <label
                                htmlFor="upload"
                                className="text-uplod block text-sm text-[#757575]"
                            >
                                Upload PDF
                            </label>
                            <img
                                className="uplod-svg inline-flex w-5 h-5 fill-[#757575] ml-4"
                                src="/pdf-upload.svg"
                                alt=""
                            ></img>
                            <input
                                className="sr-only"
                                id="upload"
                                type="file"
                                onChange={(event) => props.onFileChange(event)}
                            />
                        </form>
                        {props.formAvailability.pdfDisplayName !== "" ? (
                            <div>
                                <div className="relative mt-1">
                                    {props.formAvailability.pdfDisplayName}
                                   
                                </div>
                            </div>
                        ) : null}
                        {/* {!props.formAvailability.reportValidation ? (
                            <ValidationText error={"Please upload PDF"} />
                        ) : null} */}
                                <ValidationText error={props.validState.error.pdfDisplayName}/>

                    </div>
                </div>
                ): null}
                 <div className="m-3">
                      <label className="flex items-center">
                        <input
                          type="radio"
                          name="radio-buttons"
                          id="isredirectToUrl"
                          className="form-radio"
                          value={props.formAvailability.isredirectToUrl}
                          onChange={(event) => props.handleRadioButtons(event)}
                          checked={props.formAvailability.isredirectToUrl === true}
                        />
                       
                        <span className="text-xl  font-medium theme-color ml-2">
                        Redirection Url
                        </span>
                      </label>
                    </div>
                    {props.formAvailability.isredirectToUrl === true ? (
                <div className="flex items-center mt-9 url-part">
                <h2 className="text-xl text-[#757575] font-bold  w-[170px] ">
                        Redirection Url
                    </h2>
                    <div className="flex item-center flex-col min-w-[35%] input-w-70">
                              <input
                                id="default"
                                name="redirectToUrl"
                                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                type="text"
                                placeholder=""
                                value={props.formAvailability.redirectToUrl}
                                onChange={(event) => props.handleChange(event)}
                            //   onBlur={() => props.validateField("redirectToUrl")}
                              />
                              <div>
                                <ValidationText error={props.validState.error.redirectToUrl}/>
                                 {/* {!props.formAvailability.redirectToUrl ? (
                            <ValidationText error={"Please Enter Valid url"} />
                        ) : null} */}
                              </div>
                            </div>
                    </div>
                    ):null}
<div className="flex items-center mt-9 url-part">
                <h2 className="text-xl text-[#757575] font-bold  w-[170px]">
                Description 
                    </h2>
                    <div className="flex item-center flex-col min-w-[35%] input-w-70">
                              <input
                                id="default"
                                name="description"
                                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                                type="text"
                                placeholder=""
                                value={props.formAvailability.description}
                                onChange={(event) => props.handleChange(event)}
                            //   onBlur={() => props.validateField("redirectToUrl")}
                              />
                              <div>
                                <ValidationText error={props.validState.error.description}/>
                                 {/* {!props.formAvailability.redirectToUrl ? (
                            <ValidationText error={"Please Enter Valid url"} />
                        ) : null} */}
                              </div>
                            </div>
                    </div>
            </div>
                    
          
            <div>
                <div className="flex items-center mt-9">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1 w-[170px]">
                        Last Name
                    </h2>
                    <div className="w-[350px] px-9">
                        <DropdownSelect
                            drpIdentity={"lastNameAvailabilityId"}
                            optionArray={props.availabilityArray}
                            setFilterParameters={props.setFilterParameters}
                            value={props.formAvailability.lastNameAvailabilityId}
                        />
                        <ValidationText error={props.validState.error.lastNameAvailabilityId} />
                    </div>
                </div>
                <div className="flex items-center mt-9">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1 w-[170px]">
                        Contact Number
                    </h2>
                    <div className="w-[350px] px-9">
                        <DropdownSelect
                            drpIdentity={"contactAvailabilityId"}
                            optionArray={props.availabilityArray}
                            setFilterParameters={props.setFilterParameters}
                            value={props.formAvailability.contactAvailabilityId}
                        />
                        <ValidationText error={props.validState.error.contactAvailabilityId} />
                    </div>
                </div>
                <div className="flex items-center mt-9">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1 w-[170px]">
                        Company UEN
                    </h2>
                    <div className="w-[350px] px-9">
                        <DropdownSelect
                            drpIdentity={"UENAvailabilityId"}
                            optionArray={props.availabilityArray}
                            setFilterParameters={props.setFilterParameters}
                            value={props.formAvailability.UENAvailabilityId}
                        />
                        <ValidationText error={props.validState.error.UENAvailabilityId} />
                    </div>
                </div>
                <div className="flex items-center mt-9">
                    <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1 w-[170px]">
                        Membership Status
                    </h2>
                    <div className="w-[350px] px-9">
                        <DropdownSelect
                            drpIdentity={"membershipStatusAvailabilityId"}
                            optionArray={props.availabilityArray}
                            setFilterParameters={props.setFilterParameters}
                            value={props.formAvailability.membershipStatusAvailabilityId}
                        />
                        <ValidationText error={props.validState.error.membershipStatusAvailabilityId} />
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default FormSettingPageManagement;
