import { IsProdMode } from "../../utils/AppSetting";

// Web API url
//export const APIURL = (IsProdMode) ? "http://testingimsapi.echemportal.net/api/" : "https://localhost:44321/api/";

// export const APIURL = (IsProdMode) ? process.env.REACT_APP_PreProdApiURL : process.env.REACT_APP_DevApiURL;
export const APIURL = (IsProdMode) ? process.env.REACT_APP_PreProdApiURL : process.env.REACT_APP_DevApiURL;
// export const APIURL = "https://api.sgtech-test.infixsofttech.com/api/";
// export const APIURL = "https://sgtech-api-test.flyingcapetech.com/api/";


//Authentication services
export const AdminLogin = "Account/AdminLogin";
export const AzureLogin = "Account/AzureLogin";

//Dashboard services
export const GetAllEntries = "Dashboard/GetAllEntries";
export const GetAdminDashboardDetail = "Dashboard/GetAdminDashboardDetail"

//CorporateMemberships services 
export const GetAllCorporateMemberList = process.env.REACT_APP_GetAllCorporateMemberList;
export const GetCorporateMembersById = process.env.REACT_APP_GetCorporateMembersById;
export const DeleteCorporateMembers = process.env.REACT_APP_DeleteCorporateMembers;
export const AddEditCorporateMembers = process.env.REACT_APP_AddEditCorporateMembers;
// export const AddEditOrganisationBasicInformation = "MembershipCorporate/AddEditOrganisationBasicInformation";
export const GetAllDropdownsForCorporateMember = "MembershipCorporate/GetAllDropdownsForCorporateMember";
export const AddEditCorporateSIISAccountSegment = "MembershipCorporate/AddEditCorporateSIISAccountInformation";
export const GetCorporateBasicSIISInformation = "MembershipCorporate/GetCorporateBasicSIISInformation?id={0}";
export const AddEditCorporateEngagementTracking = "CorporateMember/AddEditCorporateEngagementTracking";
//export const GetCorporateEngagementTrackingList = "CorporateMember/GetCorporateEngagementTrackingList?id={0}";
export const GetCorporateEngagementTrackingList = "MembershipCorporate/GetEngagementTrackingListById?id={0}";
//export const GetCorporateEngagementDetailsById = "MembershipCorporate/GetCorporateEngagementDetailsById?id={0}";
export const GetCorporateEngagementDetailsById = "MembershipCorporate/GetEngagementTrackingById?id={0}";

export const AddEditCorporateChapter = "MembershipCorporate/AddEditCorporateChapter";
export const GetCorporateChapter = "MembershipCorporate/GetCorporateChapter?id={0}";
export const GetEmployeeInfomationForCorporateMemberAdmin = "MembershipCorporate/GetEmployeeInfomationForCorporateMemberAdmin?id={0}&nameSearch={1}";
export const GetAllCorporateOrganisationRole = "CorporateMember/GetAllCorporateOrganisationRole?id={0}"
export const GetChapterCommitteeRoles = "CorporateMember/GetChapterCommitteeRoles?id={0}";
export const AddEditEmployeeInformation = "MembershipCorporate/AddEditEmployeeInformation";
export const GetEmployeeInformationById = "MembershipCorporate/GetEmployeeInformationById?id={0}";
export const AddEditCorporateOrganisationRole = "CorporateMember/AddEditCorporateOrganisationRole";
export const DeleteCorporateOrganisationRole = "CorporateMember/DeleteCorporateOrganisationRole"
export const GetCorporateOrganisationRoleById = "CorporateMember/GetCorporateOrganisationRoleById?id={0}";
export const DeleteChapters = "CommitteeWorkGroup/DeleteChapters"
//New End Points for create corporate members
export const AddEditSIISCorporateAccountInformation = "MembershipCorporate/AddEditSIISCorporateAccountInformation";
export const AddEditEngagementTrackingList = "MembershipCorporate/AddEditEngagementTrackingList";
export const AddEditProspectInformation = "MembershipCorporate/AddEditProspectInformation";
export const AddEditCorporateProspect = "MembershipCorporate/AddEditCorporateProspect";
export const AddEditFinanceCardDetails = "MembershipCorporate/AddEditFinanceCardDetails";
export const AddEditGovernmentPrograme = "MembershipCorporate/AddEditGovernmentPrograme";
export const AddEditCorporateMembershipSubscription = "MembershipCorporate/AddEditCorporateMembershipSubscription";
export const AddEditChapterInformation = "MembershipCorporate/AddEditChapterInformation";
export const AddEditOrganisationBasicInformation = "MembershipCorporate/AddEditOrganisationBasicInformation";
export const AddEditOrganisationProfile = "MembershipCorporate/AddEditOrganisationProfile";
export const AddEditOrganisationBussinessDetails = "MembershipCorporate/AddEditOrganisationBussinessDetails";
export const AddEditSGTechIntrest = "MembershipCorporate/AddEditSGTechIntrest";
export const AddEditOrganisationKeyRoles = "MembershipCorporate/AddEditOrganisationKeyRoles";
export const JoinChapterById = "MembershipCorporate/JoinChapterById";
export const UpdateChapterById = "MembershipCorporate/UpdateChapterById";
export const DeleteChapterById = "MembershipCorporate/DeleteChapterById";
export const AddEditCorporateCommunication = "MembershipCorporate/AddEditCorporateCommunication";
export const AddEditOrganisationEmployees = "MembershipCorporate/AddEditOrganisationEmployees";
export const AddEditFinancialDetail = "MembershipCorporate/AddEditFinancialDetail";
export const AddEditEvenBookingData = "MembershipCorporate/AddEditEvenBookingData";
export const GetAllCorporateSectionsById = "MembershipCorporate/GetAllCorporateSectionsById?id={0}";
export const GetSIISCorporateAccountInformationById = "MembershipCorporate/GetSIISCorporateAccountInformationById?id={0}";
export const GetEngagementTrackingListById = "MembershipCorporate/GetEngagementTrackingListById?id={0}";
export const GetFinanceCardDetailsById = "MembershipCorporate/GetFinanceCardDetailsById?id={0}";
export const GetGovernmentProgrameById = "MembershipCorporate/GetGovernmentProgrameById?id={0}";
export const GetCorporateMembershipSubscriptionById = "MembershipCorporate/GetCorporateMembershipSubscriptionById?id={0}";
export const GetProspectInformationById = "MembershipCorporate/GetProspectInformationById?id={0}";
export const GetChapterInformationById = "MembershipCorporate/GetChapterInformationById?id={0}";
export const GetOrganisationBasicInformationById = "MembershipCorporate/GetOrganisationBasicInformationById?id={0}";
export const GetChapterInformation = "MembershipCorporate/GetChapterInformation?id={0}";

export const GetOrganisationProfileById = "MembershipCorporate/GetOrganisationProfileById?id={0}";
export const GetOrganisationBussinessDetailsById = "MembershipCorporate/GetOrganisationBussinessDetailsById?id={0}";
export const GetSGTechIntrestById = "MembershipCorporate/GetSGTechIntrestById?id={0}";
export const GetOrganisationKeyRolesById = "MembershipCorporate/GetOrganisationKeyRolesById?id={0}";
export const GetWorkgroupDataById = "MembershipCorporate/GetWorkgroupDataById?id={0}";
export const GetCommunicationById = "MembershipCorporate/GetCommunicationById?id={0}";
export const GetOrganisationEmployeesById = "MembershipCorporate/GetOrganisationEmployeesById?id={0}";
export const GetFinancialDetailById = "MembershipCorporate/GetFinancialDetailById?id={0}";
export const GetEvenBookingDataById = "MembershipCorporate/GetEvenBookingDataById?id={0}";
export const GetRepOfficialInformationById = "MembershipCorporate/GetOfficialRepInformationById?id={0}";
export const AddEditOfficialRepInformation = "MembershipCorporate/AddEditOfficialRepInformation"

//Privileges Services
export const AddEditPrivileges = process.env.REACT_APP_AddEditPrivileges;
export const GetPrivilegesList = process.env.REACT_APP_GetPrivilegesList;
export const GetPrivilegesById = process.env.REACT_APP_GetPrivilegesById;
export const DeletePrivileges = process.env.REACT_APP_DeletePrivileges;

//Common Services
export const GetAllAreaOfExpertise = process.env.REACT_APP_GetAllAreaOfExpertise;
export const GetAllBusinessSectors = process.env.REACT_APP_GetAllBusinessSectors;
export const GetAllChapters = process.env.REACT_APP_GetAllChapters;
export const GetAllCountries = process.env.REACT_APP_GetAllCountries;
export const GetAllStatus = process.env.REACT_APP_GetAllStatus;
export const uploadPDF = "Common/UploadPDF";
export const StoreBase64Image = process.env.REACT_APP_StoreBase64Image;
export const GetImage = "Common/GetImage";
export const GetPaymentFiles = "Common/GetPaymentFiles?id={0}&&id2={1}"
export const UploadReconciliationExcel = "Finance/UploadReconciliationExcel";

//CommunitySpotlight Services
export const AddEditCommunitySpotlight = process.env.REACT_APP_AddEditCommunitySpotlight;
export const GetCommunitySpotlight = process.env.REACT_APP_GetCommunitySpotlight;

//LeadershipPositions Services
export const AddLeadershipPositions = process.env.REACT_APP_AddLeadershipPositions;
export const GetLeadershipPositionsList = process.env.REACT_APP_GetLeadershipPositionsList;
export const GetLeadershipPositionsById = process.env.REACT_APP_GetLeadershipPositionsById;
export const DeleteLeadershipPositions = process.env.REACT_APP_DeleteLeadershipPositions;
export const UpdateLeadershipPositions = process.env.REACT_APP_UpdateLeadershipPositions;
export const GetLeadershipChapters = "LeadershipPositions/GetLeadershipChapters";
export const GetLeadershipCommittee = "LeadershipPositions/GetLeadershipCommittee";

//SGTechTV Services
export const GetSGTechTVList = process.env.REACT_APP_GetSGTechTVList;
export const GetAllSGTechTVCategory = process.env.REACT_APP_GetAllSGTechTVCategory;
export const GetSGTechTVById = process.env.REACT_APP_GetSGTechTVById;
//export const AddEditSGTechTv = process.env.REACT_APP_AddEditSGTechTv; 
export const AddEditSGTechTv = "SGTechTV/AddEditSGTechTv";
export const DeleteSGTechTV = "SGTechTV/DeleteSGTechTV";
export const GetStatusForSGtechTv = "SGTechTV/GetStatusForSGtechTv";
export const SGTechTVFeaturedCheck = "SGTechTV/SGTechTVFeaturedCheck";


//Resource Services
export const GetAllResourcesList = process.env.REACT_APP_GetAllResourcesList;
export const GetResourceById = process.env.REACT_APP_GetResourceById;
export const DeleteResources = process.env.REACT_APP_DeleteResources;
export const AddEditResources = process.env.REACT_APP_AddEditResources;
export const GetAllResourceTypes = process.env.REACT_APP_GetAllResourceTypes;
export const GetAllResourceTopics = process.env.REACT_APP_GetAllResourceTopics;
export const AddEditResourceTaggingDetails = process.env.REACT_APP_AddEditResourceTaggingDetails;
export const GetResourceTaggingDetails = process.env.REACT_APP_GetResourceTaggingDetails;

//Banner Services
// export const AddEditBanners = process.env.REACT_APP_AddEditBanners;
// export const GetBanners = process.env.REACT_APP_GetBanners;
export const AddEditBanners = "Banners/AddEditBanners";
export const GetBanners = "Banners/GetBanners?id={0}";
export const ClearAllBannersByPositionId = "Banners/ClearAllBannersByPositionId?id={0}";



// Membership Campaign Services
export const GetMembershipCampaignList = process.env.REACT_APP_GetMembershipCampaignList;
export const ActiveInActiveCampaign = "MembershipCampaign/ActiveInActiveMembershipCampaignStatusById";
export const AddEditMembershipCampaign = "MembershipCampaign/AddEditMembershipCampaign";
export const GetMembershipCampaignById = "MembershipCampaign/GetMembershipCampaignById?id={0}";
export const DeleteMembershipCampaign = "MembershipCampaign/DeleteMembershipCampaign";

// Membership Pricing Services
export const AddMembershipPricing = "MembershipPricing/AddEditMembershipPricing";
export const GetMembershipPricing = "MembershipPricing/GetMembershipPricing";

//Pages Management Services
export const GetPagesMainModulesList = "PagesManagement/GetPagesMainModulesList";
export const GetPagesDetailsList = "PagesManagement/GetPagesDetailsList";
export const GetAllDropdownsForViewPages = "PagesManagement/GetAllDropdownsForViewPages";
export const GetPageDetailByIds = "PagesManagement/GetPageDetailByIds?MainMenuId={0}&SubMenuId={1}&PageNameId={2}";
export const GetSubModulesListByMainModuleId = "PagesManagement/GetPagesSubModulesListById?id={0}";
export const SavePageDetails = "PagesManagement/AddEditPageDetail";
export const UpdatePageDetails = "PagesManagement/UpdatePageDetails";
export const GetAllDropdownsForPages = "PagesManagement/GetAllDropdownsForPages";
export const AddEditPartialPage = "PagesManagement/AddEditPartialPage";
export const GetPartialPageComponentsById = "PagesManagement/GetPartialPageComponentsById?id={0}";
export const GetSubPagesListForSubModuleById = "PagesManagement/GetSubPagesListForSubModuleById?id={0}";
export const AddPageNameForSubModule = "PagesManagement/AddPageNameForSubModule";
export const DeletePageNamesById = "PagesManagement/DeletePageNamesById";
export const GetPageDetailByPagesDetailsId = "PagesManagement/GetPageDetailByPagesDetailsId?id={0}";
export const DeleteStandAlonePageById = "PagesManagement/DeleteStandAlonePageById?id={0}";
export const AddEditFormDetails = "PagesManagement/AddEditFormDetails";
export const GetFormDetailsByPagesDetailsId = "PagesManagement/GetFormDetailsByPagesDetailsId?id={0}";




//Notification Announcement Services
export const GetNotifiicationsList = "NotificationAnnouncement/GetNotifiicationsList";
export const AddEditNotification = "NotificationAnnouncement/AddEditNotification";
export const GetNotificationsById = "NotificationAnnouncement/GetNotificationsById?id={0}";
export const DeleteNotification = "NotificationAnnouncement/DeleteNotification";

//Authority Limit Services 
export const GetAuthorityLimitList = "AuthorityLimit/GetAuthorityLimitList";
export const GetAllMembershipType = "AuthorityLimit/GetAllMembershipType";
export const GetAllMembershipRevenue = "AuthorityLimit/GetAllMembershipRevenue";
export const GetAllMembershipCategory = "AuthorityLimit/GetAllMembershipCategory";
export const GetAllMembershipFeeType = "AuthorityLimit/GetAllMembershipFeeType";
export const AddEditAuthorityLimit = "AuthorityLimit/AddEditAuthorityLimit";
export const GetAllMembershipTenure = "AuthorityLimit/GetAllMembershipTenure";
export const GetAuthorityLimitById = "AuthorityLimit/GetAuthorityLimitById?id={0}";
export const DeleteAuthorityLimit = "AuthorityLimit/DeleteAuthorityLimit";

// export const GetAllMembershipTenure = "AuthorityLimit/GetAllMembershipTenure";

//Membership Task List Services
export const GetAllTasksList = "MembershipTask/GetAllTasksList";
export const AddEditMembershipTask = "MembershipTask/AddEditMembershipTask";
export const GetTaskByCompanyId = "MembershipTask/GetTaskByCompanyId?id={0}";
export const GetAllSources = "MembershipTask/GetAllSources";
export const GetAllMembershipTypes = "AuthorityLimit/GetAllMembershipType";
export const GetAllTransactionaTypes = "MembershipTask/GetAllTransactionaTypes";
// export const GetPriceTabelForTaskListProcess = "MembershipTask/GetPriceTabelForTaskListProcess";
export const GetAllBillingCategories = "CorporateMember/GetAllCorporateMemberBillingCategory";
export const GetAllCorporateProspectSource = "CorporateMember/GetAllCorporateProspectSource";
export const GetAllDropdownsForMembershipTaskList = "MembershipTask/GetAllDropdownsForMembershipTaskList";
export const GetPriceTabelByCorporateMemberId = "MembershipTask/GetPriceTabelByCorporateMemberId?id={0}";
export const GetCalculatedFinalPrice = "MembershipTask/GetCalculatedFinalPrice";
export const AddEditMembershipTaskAndSendPaymentLink = "MembershipTask/AddEditMembershipTaskAndSendPaymentLink";
export const GetExistingDiscountTableByTaskId = "MembershipTask/GetExistingDiscountTableByTaskId?id={0}";
export const SendInvoiceEmailToCorporateMember = "MembershipTask/SendInvoiceEmailToCorporateMember";
export const SendQuotationEmailToCorporateMember = "MembershipTask/SendQuotationEmailToCorporateMember";
export const GetAllPriceTables = "MembershipTask/GetAllPriceTables";
export const AddEditMembershipTaskAndSendForApproval = "MembershipTask/AddEditMembershipTaskAndSendForApproval";
export const SendEscalateMailToMember = "MembershipTask/SendEscalateMailToMember";
export const DeleteTaskListDetailsByTaskId = "MembershipTask/DeleteTaskListDetailsByTaskId?id={0}";

// Chapter X Committee Workgroup Services

export const AddEditExcoMember = "CommitteeWorkGroup/AddEditExcoMembers";
export const GetAllExcoPosition = "CommitteeWorkGroup/GetAllExcoPosition";
export const GetCommitteeWorkGroupList = "CommitteeWorkGroup/GetAllCommitteeWorkGroupList";
export const GetAllOrganisationName = "IndividualMember/GetAllOrganisationName";
// export const GetExcoMemberList = "CommitteeWorkGroup/GetExcoMemberList?id={0}";
export const GetExcoMemberList = "CommitteeWorkGroup/GetExcoMemberList";
export const GetExcoMemberById = "CommitteeWorkGroup/GetExcoMemberById?id={0}";
export const GetAllDropdownForChapterXCommittee = "CommitteeWorkGroup/GetAllDropdownForChapterXCommittee";
export const AddEditChapterDetails = "CommitteeWorkGroup/AddEditChapterDetails"
export const DeleteCommitteeWorkgroupMapping = "CommitteeWorkGroup/DeleteCommitteeWorkgroupMapping";
export const DeleteCommittees = "CommitteeWorkGroup/DeleteCommittees";
export const SearchExcoMemberByEmail = "CommitteeWorkGroup/SearchExcoMemberByEmail";
export const DeleteEXCOMembers = "CommitteeWorkGroup/DeleteEXCOMembers";


//Membership Fee Approval

export const GetAllPendingApprovalTasks = "MembershipFeeApproval/GetAllPendingApprovalTasks";
export const GetPriceTablesForMembershipFeeApproval = "MembershipFeeApproval/GetPriceTablesForMembershipFeeApproval?id={0}";
export const UpdatePriceTablesForMembershipFeeApproval = "MembershipFeeApproval/UpdatePriceTablesForMembershipFeeApproval";

// Individual Member 
export const GetAllDropdownsForIndividualMember = "IndividualMember/GetAllDropdownsForIndividualMember";
export const GetIndividualEngagementTrackingList = "IndividualMember/GetIndividualEngagementTrackingList?id={0}";
export const AddEditEngagementTracking = "IndividualMember/AddEditEngagementTracking";
export const GetIndividualEngagementTrackingById = "IndividualMember/GetIndividualEngagementTrackingById?id={0}";
export const AddEditIndividualBasicInformation = "IndividualMember/AddEditIndividualBasicInformation";
export const AddEditIndividualSIISInformation = "IndividualMember/AddEditIndividualSIISInformation";
export const GetIndividualBasicInformation = "IndividualMember/GetIndividualBasicInformation?id={0}";
export const GetIndividualSIISInformation = "IndividualMember/GetIndividualSIISInformation?id={0}";
export const AddEditIndividualCommittee = "IndividualMember/AddEditIndividualCommittee";
export const GetIndividualCommittee = "IndividualMember/GetIndividualCommittee?id={0}";
export const AddEditIndividualMemberSubscription = "IndividualMember/AddEditIndividualMemberSubscription";
export const GetIndividualMemberSubscription = "IndividualMember/GetIndividualMemberSubscription?id={0}";
export const GetIndividualContributionList = "IndividualMember/GetIndividualContributionList?id={0}";
export const AddEditIndividualContribution = "IndividualMember/AddEditIndividualContribution";
export const GetIndividualMemberList = "IndividualMember/GetIndividualMemberList";
export const ActiveInActiveIndividualMember = "IndividualMember/ActiveInActiveIndividualMember";
export const DeleteIndividualMember = "IndividualMember/DeleteIndividualMember";
export const DeleteIndividualContribution = "IndividualMember/DeleteIndividualContribution";







// Membership Corporate Services
export const Getofficialrepinformation = "CorporateMember/Getofficialrepinformation?id={0}";
export const GetMembershipCorporateList = "CorporateMember/GetMembershipCorporateList";
export const GetEmailInformationById = "CorporateMember/GetEmailInformationById?id={0}";
export const ActiveInActiveMembershipCorporate = "CorporateMember/ActiveInActiveMembershipCorporate";
export const DeleteMembershipCorporate = "CorporateMember/DeleteMembershipCorporate";
export const GetChapterListByCorporateId = "IndividualMember/GetChapterListForIndividualDashboard?id={0}";
export const GetGovernmentProgrammeDetailByProjectId = "MembershipCorporate/GetGovernmentProgrammeDetailByProjectId?id={0}";
export const GetCorporateFinancialInfo = "MembershipCorporate/GetCorporateFinancialInfo?id={0}";
export const GetCorporateFinanceReceiptInvoice = "MembershipCorporate/GetCorporateFinanceReceiptInvoice?id={0}&id2={1}&id3={2}";
export const RenewCorporateMember = "WebMembershipCorporate/RenewCorporateMember";
export const SearchIndividualMemberByEmailForOrgRole = "WebMembershipCorporate/SearchIndividualMemberByEmailForOrgRole";
export const MarkIndivisualMemberAsNonMember = "MembershipCorporate/MarkIndivisualMemberAsNonMember?id={0}";
export const GetPurchaseChapterDataById = "MembershipCorporate/GetPurchaseChapterDataById?id={0}";
export const GetAllAvailableChapterListForUpcomingTenure = "WebMembershipCorporate/GetAllAvailableChapterListForUpcomingTenure?id={0}";


// export const AddEditCorporateSIISAccountSegment = "MembershipCorporate/AddEditCorporateSIISAccountSegment";
// export const GetCorporateBasicSIISInformation = "MembershipCorporate/GetCorporateBasicSIISInformation";

export const GetAllCorporateStatus = "MembershipCorporate/GetAllCorporateStatus";


// New API end points
export const AddEditSGTechInterestProfile = "IndividualMember/AddEditSGTechInterestProfile";
export const AddEditCommunicationProfile = "IndividualMember/AddEditCommunicationProfile";
export const GetIndividualSGTechInterest = "IndividualMember/GetIndividualSGTechInterest?id={0}";
export const GetIndividualCommunication = "IndividualMember/GetIndividualCommunication?id={0}";
export const GetAllIndividualProfileDetail = "IndividualMember/GetAllIndividualProfileDetail?id={0}";
export const GetEmploymentInformation = "IndividualMember/GetEmploymentInformation?id={0}";
export const AddEditEmploymentInformation = "IndividualMember/AddEditEmploymentInformation";
export const GetEmailHistoryForIndividualMemberById = "IndividualMember/GetEmailHistoryForIndividualMemberById?id={0}";
export const GetBookingListForIndividualMemberById = "IndividualMember/GetBookingListForIndividualMemberById";

//Partnership Services
export const GetAllGovernmentPartnersList = "Partnerships/GetAllGovernmentPartnersList";
export const GetAllGovernmentProject = "Partnerships/GetAllGovernmentProject";
export const GetAllStrategicMembersList = "Partnerships/GetAllStrategicMembersList";
export const GetAllProjectReviewList = "Partnerships/GetAllProjectReviewList";
export const DeleteGovernmentAgency = "Partnerships/DeleteGovernmentAgency";
export const DeleteGovernmentProject = "Partnerships/DeleteGovernmentProject";
export const DeleteGovernmentStrategicMember = "Partnerships/DeleteGovernmentStrategicMember";
export const DeleteGovernmentAgencyOfficer = "Partnerships/DeleteGovernmentAgencyOfficer";
export const GetAllAgencyDropdown = "Partnerships/GetAllAgencyDropdown";
export const GetAllMemberDropdown = "Partnerships/GetAllMemberDropdown";
export const GetAllChapterDropdown = "Partnerships/GetAllChapterDropdown";
export const GetAllAgencyOfficerList = "Partnerships/GetAllAgencyOfficerList?id={0}";
export const AddEditAgencyOfficer = "Partnerships/AddEditAgencyOfficer";
export const GetAgencyOfficerById = "Partnerships/GetAgencyOfficerById?id={0}";
export const AddEditAgencyPartner = "Partnerships/AddEditAgencyPartner";
export const GetGovernmentAgencyById = "Partnerships/GetGovernmentAgencyById?id={0}";
export const GetAllCompanyForStrategicMember = "Partnerships/GetAllCompanyForStrategicMember";
export const GetCompanyOfficerByCompanyForStrategicMember = "Partnerships/GetCompanyOfficerByCompanyForStrategicMember?id={0}&nameSearch={1}";
export const AddStrategicMember = "Partnerships/AddStrategicMember";
export const GetAllStrategicMemberList = "Partnerships/GetAllStrategicMemberList";
export const GetAllSecretariatList = "Partnerships/GetAllSecretariatList";
export const GetAllGovernmentAgencyList = "Partnerships/GetAllGovernmentAgencyList";
export const AddEditProject = "Partnerships/AddEditProject";
export const GetGovernmentProjectById = "Partnerships/GetGovernmentProjectById?id={0}";
export const AddAgencyOfficerToAddEditProject = "Partnerships/AddAgencyOfficerToAddEditProject";
export const GetAgencyOfficerListForAddEditProject = "Partnerships/GetAgencyOfficerListForAddEditProject?id={0}";
export const GetStrategicMemberListForAddEditProject = "Partnerships/GetStrategicMemberListForAddEditProject?id={0}";
export const UpdateAgencyOfficerKeyStatusForAddeEditProject = "Partnerships/UpdateAgencyOfficerKeyStatusForAddeEditProject";
export const DeleteAgencyOfficerFromAddEditProject = "Partnerships/DeleteAgencyOfficerFromAddEditProject";
export const DeleteStrategicMemberFromAddEditProject = "Partnerships/DeleteStrategicMemberFromAddEditProject";

// Field Management 
export const GetAllTagTypeDropdown = "FieldManagement/GetAllTagTypeDropdown";
export const GetAllTagList = "FieldManagement/GetAllTagList";
export const DeleteTags = "FieldManagement/DeleteTags";
export const GetTagDetailById = "FieldManagement/GetTagDetailById?id={0}";
export const AddEditTag = "FieldManagement/AddEditTag";
export const AddSuperReport = "FieldManagement/AddSuperReport";
export const GetAllSuperReportsDropdown = "FieldManagement/GetAllSuperReportsDropdown";
export const GetAllRecordsBySuperReportId = "FieldManagement/GetAllRecordsBySuperReportId?DropdownId={0}&ReportFilter={1}";

export const GetAllProjectTypeDropdown = "FieldManagement/GetAllProjectTypeDropdown";
export const GetEmailTemplateList = "FieldManagement/GetEmailTemplateList";
export const DeleteEmailTemplate = "FieldManagement/DeleteEmailTemplate";
export const GetEmailTemplateById = "FieldManagement/GetEmailTemplateById?id={0}";
export const AddEditEmailTemplate = "FieldManagement/AddEditEmailTemplate";

export const GetDropdownRecordList = "FieldManagement/GetDropdownRecordList";
export const GetDropdownDatabyId = "FieldManagement/GetDropdownDatabyId?id={0}";
export const DeleteDropdownData = "FieldManagement/Deletedropdowndata";
export const AddEditDropdownData = "FieldManagement/AddEditDropdownData";
export const GetTableDataById = "FieldManagement/GetTableDataById?DropdownId={0}&TableDataId={1}";

// Admin 
export const AddEditAdminDetails = "Account/AddEditAdminDetails";
    
export const GetRolesForAdmin = "Account/GetRolesForAdmin";
export const GetAdminDetailList = "Account/GetAdminDetailList";
export const GetAdminDetailById = "Account/GetAdminDetailById?id={0}";
export const DeleteAdminDetailsById = "Account/DeleteAdminDetailsById?id={0}";
export const ForgotPassword = "Account/ForgotPassword";
export const ResetPassword = "Account/ResetPassword";

//Email Management
export const GetEmailHistoryList = "EmailManagement/GetEmailHistoryList";
export const DeleteEmailDetailsById = "EmailManagement/DeleteEmailDetailsById?id={0}";

export const GetAllRecipientsDropdown = "EmailManagement/GetAllRecipientsDropdown";
export const GetEmailById = "EmailManagement/GetEmailById?id={0}";
export const AddEditEmail = "EmailManagement/AddEditEmail";


//Prasad
//Web Membership Corporate
export const GetALLMembershipEnquiryList = "WebMembershipCorporate/GetALLMembershipEnquiryList";
export const GetMembershipEnquiryById = "WebMembershipCorporate/GetMembershipEnquiryById?id={0}";


//Entry Management
export const AddEditEntryDetails = "EntryManagement/AddEditEntryDetails";
export const GetAllDropDownForEntryManagement = "EntryManagement/GetAllDropDownForEntryManagement?id={0}";
export const GetEntryDetailList = "EntryManagement/GetEntryDetailList";
export const GetEntryDetailsById = "EntryManagement/GetEntryDetailsById?id={0}";
export const DeleteEntryDetailsByIds = "EntryManagement/DeleteEntryDetailsByIds";
export const GetLessonTimeSlotDetailsById = "EntryManagement/GetLessonTimeSlotDetailsById?id={0}";
export const DeleteEntryTimeSlotById = "EntryManagement/DeleteEntryTimeSlotById";
export const AddEditOtherEntryDetails = "EntryManagement/AddEditOtherEntryDetails";
export const AddEditFormQuestion = "EntryManagement/AddEditFormQuestion";
export const GetFormQuestionByEventId = "EntryManagement/GetFormQuestionByEventId?id={0}";
export const GetOtherEntryDetailsByEventId = "EntryManagement/GetOtherEntryDetailsByEventId?id={0}";
export const AddEditEntryLessonTimeSlotDetails = "EntryManagement/AddEditEntryLessonTimeSlotDetails";
export const GetEntryPricingDetail = "EntryManagement/GetEntryPricingDetail";
export const AddEditEntryPricingDetail = "EntryManagement/AddEditEntryPricingDetail";
export const DeleteEntryPricingPackage = "EntryManagement/DeleteEntryPricingPackage";
export const GetLocationAreaByPostalCode = "EntryManagement/GetLocationAreaByPostalCode?id={0}";
export const GetSGTechAddressesForEntries = "EntryManagement/GetSGTechAddressesForEntries";
export const UpdateLessonDetailsById = "EntryManagement/UpdateLessonDetailsById";

export const GetAllDropDownForCampaignTagging = "EntryManagement/GetAllDropDownForCampaignTagging";
export const GetCampaignTaggingDetailsByEventId = "EntryManagement/GetCampaignTaggingDetailsByEventId?id={0}";
export const AddEditCampaignTaggingDetails = "EntryManagement/AddEditCampaignTaggingDetails";
export const AddEditEntryProgrammeDetail = "EntryManagement/AddEditEntryProgrammeDetail";
export const GetEntryProgrammeDetailById = "EntryManagement/GetEntryProgrammeDetailById?id={0}";

export const AddEditEventSectionDetails = "EntryManagement/AddEditEventSectionDetails";
export const GetEventSectionDetails = "EntryManagement/GetEventSectionDetails?id={0}";
export const AddEditEventFormPriceList = "EntryManagement/AddEditEventFormPriceList";
export const GetPriceDetailsForFormByEventId = "EntryManagement/GetPriceDetailsForFormByEventId?id={0}";

export const GetAllMarketingTrackingURList = "EntryManagement/GetAllMarketingTrackingURList";
export const GetAllMarketingTrackingURLPartnerList = "EntryManagement/GetAllMarketingTrackingURLPartnerList";
export const GetAllMarketingTrackingURLEventList = "EntryManagement/GetAllMarketingTrackingURLEventList?id={0}";
export const AddEditMarketingTrackingURL = "EntryManagement/AddEditMarketingTrackingURL";
export const DeleteMarketingTrackingURL = "EntryManagement/DeleteMarketingTrackingURL?id={0}";
export const GetMarketingTrackingURLDetailById = "EntryManagement/GetMarketingTrackingURLDetailById?id={0}";
export const GetEntryTimeSlotDetailForTaggingByEventId = "EntryManagement/GetEntryTimeSlotDetailForTaggingByEventId?id={0}";
export const DeleteEventQuestionById = "EntryManagement/DeleteEventQuestionById?id={0}";
export const DeleteEventAnswerById = "EntryManagement/DeleteEventAnswerById?id={0}";
export const DeleteSpeakerById = "EntryManagement/DeleteSpeakerById?id={0}";
export const DeleteAdditionalDescriptionById = "EntryManagement/DeleteAdditionalDescriptionById?id={0}";
export const DeleteProgrammeTimeById = "EntryManagement/DeleteProgrammeTimeById?id={0}";
export const GetFeedbackQuestionEntryDetailList = "EntryManagement/GetFeedbackQuestionEntryDetailList";
export const GetAllFeedbackAnswerListByEventId = "EntryManagement/GetAllFeedbackAnswerListByEventId?id={0}";

// Finance Management
export const GetFinanceGSTSettingList = "Finance/GetFinanceGSTSettingList";
export const GetFinanceClassCodeSettingList = "Finance/GetFinanceClassCodeSettingList";
export const GetFinanceAccountCodeSettingList = "Finance/GetFinanceAccountCodeSettingList";
export const GetFinanceCreditNoteApprovalList = "Finance/GetFinanceCreditNoteApprovalList";
export const AddEditFinanceGST = "Finance/AddEditFinanceGST";
export const AddEditFinanceClassCode = "Finance/AddEditFinanceClassCode";
export const AddEditFinanceAccountCode = "Finance/AddEditFinanceAccountCode";
export const AddEditFinanceCreditNoteApproval = "Finance/AddEditFinanceCreditNoteApproval";
export const GetCreditNoteInfoForCorporateMember = "Finance/GetCreditNoteInfoForCorporateMember?id={0}&id2={1}";
export const GetCreditNoteInfoForEventBookingByBookingId = "Finance/GetCreditNoteInfoForEventBookingByBookingId?id={0}";
export const GetCancellationReasonForCreditNote = "Finance/GetCancellationReasonForCreditNote";
export const AddFinanceCreditNote = "Finance/AddFinanceCreditNote";
export const DeleteFinanceGlobal = "Finance/DeleteFinanceGlobal";
export const LoginToCorporateMemberForIndividualMember = "IndividualMember/LoginToCorporateMemberForIndividualMember";

//bookings 
export const GetbookingsList = "Booking/GetbookingsList";
export const GetBookingCancellationList = "Booking/GetBookingCancellationList";
export const GetBookingDetailsById = "Booking/GetBookingDetailsById?id={0}";
export const GetBookingCancellationDetailsById = "Booking/GetBookingCancellationDetailsById?id={0}";
export const GetBookingDetailsByBookingId = "Booking/GetBookingDetailsByBookingId?attendeeId={0}&bookingId={1}";
export const GetInvoiceReceiptPdfByBookingId = "IndividualMember/GetInvoiceReceiptPdfByBookingId?id={0}&type={1}"
export const GetEventPaymentReconcilationList = "Booking/GetEventPaymentReconcilationList";
export const GetPaymentProofListDetailsByPaymentProofId = "Booking/GetPaymentProofListDetailsByPaymentProofId?id={0}&typeid={1}";
export const SavePaymentReconcilationDetailsByAdmin = "Booking/SavePaymentReconcilationDetailsByAdmin";
export const AcceptTotalPaidAmountInReconcilation = "Booking/AcceptTotalPaidAmountInReconcilation?id={0}";
export const AcceptTotalMembershipPaidAmountInReconciliation = "MembershipPayment/AcceptTotalMembershipPaidAmountInReconciliation";
export const AcceptTotalExternalInvoicePaidAmountInReconciliation = "Booking/AcceptTotalExternalInvoicePaidAmountInReconciliation?id={0}";
export const AcceptTotalPurchaseChapterPaidAmountInReconciliation = "MembershipPayment/AcceptTotalPurchaseChapterPaidAmountInReconciliation?id={0}&membershipPaymentId={1}";
export const DeleteEventPaymentReconcilation = "Booking/DeleteEventPaymentReconcilation?id={0}";
export const GetOutstandingInvoicesList = "Booking/GetOutstandingInvoicesList";
export const AddProofInPaymentProofMapping = "Booking/AddProofInPaymentProofMapping";
export const GetAllDropdownsForIndividualBooking = "Booking/GetAllDropdownsForIndividualBooking?id={0}";
export const DownloadBookingSheetByEventId = "Booking/DownloadBookingSheetByEventId?id={0}&id2={1}";





// Adhok Invoice In Finance 
export const AddEditFinanceAdhokInvoiceDetail = "Finance/AddEditFinanceAdhokInvoiceDetail";
export const GetAllDropdownsForFinanceAdhokInvoices = "Finance/GetAllDropdownsForFinanceAdhokInvoices";
export const GetFinanceAdhocInvoiceList = "Finance/GetFinanceAdhocInvoiceList";
export const GetFinanceAdhokInvoiceDetail = "Finance/GetFinanceAdhokInvoiceDetail?id={0}";
export const AddEditFinanceExternalServiceDetail = "Finance/AddEditFinanceExternalServiceDetail";
export const GetFinanceExternalServiceDetailById = "Finance/GetFinanceExternalServiceDetailById?id={0}";
export const AddDrawdownForExternalServiceItem = "Finance/AddDrawdownForExternalServiceItem";
export const GetFinanceCreditNoteById = "Finance/GetFinanceCreditNoteById?id={0}";


// Voucher Management
export const GetAllVoucherCodeList = "Finance/GetAllVoucherCodeList";
export const GetAllFinanceCreditNoteList = "Finance/GetAllFinanceCreditNoteList";
export const GetFinanceCreditNoteStatusList = "Finance/GetFinanceCreditNoteStatusList";
export const FinanceApproveRejectCreditNoteApproval = "Finance/FinanceApproveRejectCreditNoteApproval";
export const GetVoucherCodeDetailsById = "Finance/GetVoucherCodeDetailsById?id={0}";
export const ActiveInActiveVoucherCode = "Finance/ActiveInActiveVoucherCode";
export const GetAllMemberListForVoucher = "Finance/GetAllMemberListForVoucher";
export const AddEditVoucherCodeDetails = "Finance/AddEditVoucherCodeDetails";
export const GetAllChapterListForVoucher = "Finance/GetAllChapterListForVoucher";
export const GetAllEventListForVoucherCode = "Finance/GetAllEventListForVoucherCode";
export const FinanceUpdateCreditNote = "Finance/FinanceUpdateCreditNote";
export const FinanceUpdateStatusForCreditNote = "Finance/FinanceUpdateStatusForCreditNote";
export const FinanceGetAllPendingEventApprovalList = "Finance/FinanceGetAllPendingEventApprovalList";
export const financeEventInvoiceApproval = "Finance/financeEventInvoiceApproval";
export const FinanceGetInvoice = "Finance/FinanceGetInvoice?path={0}";
export const FinanceGetCreditNoteInvoice = "Finance/FinanceGetCreditNoteInvoice?id={0}";
export const UpdatePaymentHistoryForMembershipPayment = "MembershipPayment/UpdatePaymentHistoryForMembershipPayment?id={0}";
export const UpdatePaymentHistoryForAdditionalChapter = "MembershipPayment/UpdatePaymentHistoryForAdditionalChapter?id={0}";
export const UpdatePaymentHistory = "Booking/UpdatePaymentHistory?referenceNo={0}&eventVisitorId={1}";
export const GetAllExtractInvoicesList = "Finance/GetAllExtractInvoicesList";
export const GetGSTRate = "Common/GetGSTRate";
export const ZipInvoices = "Finance/ZipInvoices";
export const FinanceAddCreditNotePaymentProofMapping = "Finance/FinanceAddCreditNotePaymentProofMapping";
export const AddCreditNoteInvoiceRecordsForBatchJob = "BatchJob/AddCreditNoteInvoiceRecordsForBatchJob?id={0}";
export const AddMembershipCreditNoteApprovalRecordsForBatchJob = "BatchJob/AddMembershipCreditNoteApprovalRecordsForBatchJob?id={0}";
export const AddEventCreditNoteApprovalRecordsForBatchJob = "BatchJob/AddEventCreditNoteApprovalRecordsForBatchJob?id={0}";
export const AddContinuosPeriodExtInvCNApprovalXeroRecords = "BatchJob/AddContinuosPeriodExtInvCNApprovalXeroRecords?id={0}";
export const ApplyVoucherCodeForMembershipPayment = "Finance/ApplyVoucherCodeForMembershipPayment";
// For Voucher Reports
export const GetAllVoucherCodeReportList = "Finance/GetAllVoucherCodeReportList";
export const GenerateVoucherCodeReport = "Finance/GenerateVoucherCodeReport";
export const DownloadSheetByVoucherCodeReportId = "Finance/DownloadSheetByVoucherCodeReportId?id={0}";

// For Deferred Income Reports
export const GetAllDeferredIncomeReportList = "Finance/GetAllDeferredIncomeReportList";
export const GenerateDeferredIncomeReport = "Finance/GenerateDeferredIncomeReport";
export const DownloadSheetByDeferredIncomeReportId = "Finance/DownloadSheetByDeferredIncomeReportId?id={0}";

// For Transaction Reports
export const GetAllTransactionReportList = "Finance/GetAllTransactionReportList";
export const GenerateTransactionReport = "Finance/GenerateTransactionReport";
export const DownloadSheetByTransactionReportId = "Finance/DownloadSheetByTransactionReportId?id={0}";
export const GetTransactionRecordList = "Finance/GetTransactionRecordList";
export const DownloadSheetForTransactionRecord = "Finance/DownloadSheetForTransactionRecord";
export const GetGenAICyberConnectRecordList = "Finance/GetGenAICyberConnectRecordList";
export const DownloadSheetForGenAICyberConnectRecord = "Finance/DownloadSheetForGenAICyberConnectRecord" 
export const GetAllDropdownsForOrganisationRoleReports = "Finance/GetAllDropdownsForOrganisationRoleReports" 



export const BuyChapterForCorporateMember = "MembershipPayment/BuyChapterForCorporateMember";
export const SendReconciliationPaymentLinkForBuyChapter = "MembershipPayment/SendReconciliationPaymentLinkForBuyChapter";

export const GetAllWeeklyBatchJobRecords = "BatchJob/GetAllWeeklyBatchJobRecords";
export const GetAllMonthlyBatchJobRecords = "BatchJob/GetAllMonthlyBatchJobRecords";
export const GenerateWeeklyBatchJob = "BatchJob/GenerateWeeklyBatchJob?id={0}";
export const GenerateMonthlyBatchJob = "BatchJob/GenerateMonthlyBatchJob?id={0}";
export const DownloadSheetByBatchJobId = "BatchJob/DownloadSheetByBatchJobId?id={0}";


export const GetAllChartsOfAccountCodeList = "BatchJob/GetAllChartsOfAccountCodeList";
export const GetAllDropdownsForBatchJob = "BatchJob/GetAllDropdownsForBatchJob";
export const AddEditChartsOfAccountCode = "BatchJob/AddEditChartsOfAccountCode";

// Go Global API
export const GetGoGlobalContactRequestList = "GoGlobalAccount/GetGoGlobalContactRequestList";
export const GetGoGlobalMemberDetailsById = "GoGlobalAccount/GetGoGlobalMemberDetailsById?id={0}";
export const GetGoGlobalAccountApprovalList = "GoGlobalAccount/GetGoGlobalAccountApprovalList";
//export const GetStatusList = "GoGlobalAccount/GetStatusList";
export const GetAllDropDownsForGoGlobalMembers = "GoGlobalAccount/GetAllDropDownsForGoGlobalMembers";
export const ApproveGoGlobalMemberDetails = "GoGlobalAccount/ApproveGoGlobalMemberDetails";
export const GetAllGoGlobalClickAnalyticsList = "GoGlobal/GetAllGoGlobalClickAnalyticsList";
export const AddEditGoGlobalDetails = "GoGlobal/AddEditGoGlobalDetails";
export const GetCorporateDetailForGoGLobal = "GoGlobal/GetCorporateDetailForGoGLobal?id={0}";
export const GetDropdownDataByName = "GoGlobal/GetDropdownDataByName";


// Membership Reports
export const GenerateMembershipReport = "BatchJob/GenerateMembershipReport";
export const GetAllMembershipReportList = "BatchJob/GetAllMembershipReportList";
export const DownloadSheetByMembershipReportId = "BatchJob/DownloadSheetByMembershipReportId?id={0}";

// //chapter report 
// export const DownloadCorporateMemberDirectoryReport = "BatchJob/DownloadCorporateMemberDirectoryReport"
// export const GetCorporateMemberDirectoryList = "WebMembershipCorporate/GetCorporateMemberDirectoryList";

// AR Aging Reports
export const GetAllARAgingReportList = "BatchJob/GetAllARAgingReportList";
export const GenerateARAgingReport = "BatchJob/GenerateARAgingReport";
export const DownloadARAgingSheetByARAgingReportId = "BatchJob/DownloadARAgingSheetByARAgingReportId?id={0}";

// GST Reports
export const GetAllGSTReportList = "BatchJob/GetAllGSTReportList";
export const GenerateGSTReport = "BatchJob/GenerateGSTReport";
export const DownloadGSTSheetByGSTReportId = "BatchJob/DownloadGSTSheetByGSTReportId?id={0}";

// Form Report

export const GetFormQuestionAnswerEntryDetailList = "EntryManagement/GetFormQuestionAnswerEntryDetailList";
export const GetAllFormQuestionAnswerListByEventId = "EntryManagement/GetAllFormQuestionAnswerListByEventId?id={0}";

// Membership Reports
export const GenerateOrganisationRoleReport = "BatchJob/GenerateOrganisationRoleReport";
export const GenerateChapterMemberReport = "BatchJob/GenerateChapterMemberReport";
export const GetAllChapterMemberTableReportList = "BatchJob/GetAllChapterMemberTableReportList";
export const GetAllRetentionReportList = "BatchJob/GetAllRetentionReportList";


export const GenerateChapterMemberCountTableReport = "BatchJob/GenerateChapterMemberCountTableReport";

export const GenerateRetentionReport = "BatchJob/GenerateRetentionReport";

export const GetAllOrganisationRoleReportList = "BatchJob/GetAllOrganisationRoleReportList";
export const GetAllChapterMemberReportList = "BatchJob/GetAllChapterMemberReportList";

export const DownloadCorporateMemberDirectoryReport = "BatchJob/DownloadCorporateMemberDirectoryReport";
export const DownloadSheetByOrganisationRoleReportId = "BatchJob/DownloadSheetByOrganisationRoleReportId?id={0}";
export const DownloadSheetByChapterMemberReportId = "BatchJob/DownloadSheetByChapterMemberReportId?id={0}";

export const DownloadSheetByChapterMemberTableReportId = "BatchJob/DownloadSheetByChapterMemberTableReportId?id={0}";
export const DownloadSheetByRetentionReportId = "BatchJob/DownloadSheetByRetentionReportId?id={0}";

export const DownloadSheetForXeroInvoiceExtractionRecords = "Finance/DownloadSheetForXeroInvoiceExtractionRecords";


//  DT-SME  
export const AcceptTotalDTSMEPackagePaidAmountInReconciliation = "DTSME/AcceptTotalDTSMEPackagePaidAmountInReconciliation?id={0}&membershipPaymentId={1}";
export const GetDTSMEMarketPlaceCompanyInformationList = "DTSME/GetDTSMEMarketPlaceCompanyInformationList";
export const GetDTSMEMarketPlaceCompanyInformationById = "DTSME/GetDTSMEMarketPlaceCompanyInformationById?id={0}";
export const AddEditDTSMEMarketPlaceCompanyInformation = "DTSME/AddEditDTSMEMarketPlaceCompanyInformation";
export const GetALLMarketPlaceCompanyInformationList = "DTSME/GetALLMarketPlaceCompanyInformationList";
export const GetAllDropdownsForDTSME = "DTSME/GetAllDropdownsForDTSME";
export const DownloadSheetForDTSMEPurchaseReport = "DTSME/GenerateDTSMEPurchaseReport";
export const GetDTSMEPurchaseRecordList="DTSME/GetDTSMEPurchaseRecordList";

export const AddEditPartnerConsultantInformation="DTSME/AddEditPartnerConsultantInformation";
export const GetPartnerConsultantInformationList="DTSME/GetPartnerConsultantInformationList";
export const GetPartnerConsultantInformationById="DTSME/GetPartnerConsultantInformationById?id={0}";
export const DeleteGetPartnerConsultantInformationById="DTSME/DeleteGetPartnerConsultantInformationById?id={0}";

export const AddEditDTSMEMentorsInformation = "DTSME/AddEditDTSMEMentorsInformation";
export const GetALLDTSMEMentorsInformation = "DTSME/GetALLDTSMEMentorsInformation";
export const GetDTSMEMentorsInformationById = "DTSME/GetDTSMEMentorsInformationById?id={0}"
export const DeleteDTSMEMentorsInformationById = "DTSME/DeleteDTSMEMentorsInformationById?id={0}";

export const AddEditSolutionProvidersInformation="DTSME/AddEditSolutionProvidersInformation";
export const GetALLSolutionProviderInformationList="DTSME/GetALLSolutionProviderInformationList";
export const GetSolutionProviderInformationById="DTSME/GetSolutionProviderInformationById?id={0}";
export const DeleteSolutionProviderById="DTSME/DeleteSolutionProviderById?id={0}";

// Reconciliation Excel
export const DownloadReconciliationExcelTemplate = "Finance/DownloadReconciliationExcelTemplate";
export const GetAllReconciliationUploadedExcelList = "Finance/GetAllReconciliationUploadedExcelList";
export const DownloadSheetByReconciliationDataFileId = "Finance/DownloadSheetByReconciliationDataFileId?id={0}";

export const GetCorporateMemberDirectoryList = "WebMembershipCorporate/GetCorporateMemberDirectoryList";
