import moment from "moment";
import React, { Children, Component } from "react";
import Selector from "../../components/dropdown/commonDropdown/Selector";
import BatchJobExtractionServices from "../../services/axiosServices/apiServices/BatchJobExtractionServices";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { saveAs } from "file-saver";
import PageLoader from "../../components/common/pageLoader/PageLoader";
import { validate,isValidForm } from "../../utils/validation/CommonValidator";
import { CommonValidationMessages } from "../../utils/Messages";
import ValidationText from "../../utils/validation/ValidationText";

export default class ChapterMemberReport extends Component {
    constructor(props) {
        super(props);
        this.batchJobExtractionServices = new BatchJobExtractionServices();
        this.financeServices = new FinanceServices();

        this.swalServices = new SwalServices();
        this.state = {
            chaptercategoryData:[],
            membershipcategorydata:[],
            organisationroledata:[]
,            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "CreatedAt",
                orderFlag: 0,
                searchText: "",
                organisationRoleStatus:0,
                selectedChapterCategory: [],
                selectedMembershipCategory: [],
            },
            statusList:[
                {
                    value: 0,
                    label: "Inactive",
                },
                {
                    value: 1,
                    label: "Active",
                },
                {
                    value: 2,
                    label: "Ex-member",
                },
                {
                    value: 3,
                    label: "Active-New",
                },
            ],
          temp:{
            statusData:[],
            chaptercategoryData:[]
          },
            statusData:[],
            chapterList: [],
            organisationRoleList:[],
            membershipCategoryList: [],
            isLoading: false,
            currentDate: new Date(),
            organisationRoleReportList: [],
            totalCount: 0,
            validState: {
                isValid: true,
                error: {},
            },
            validationRule: {
                statusData: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "status"),
                    },
                ],
                chaptercategoryData: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "Chapter"),
                    },
                    
                ],
               
            },
        };
    }

    componentDidMount = () => {
        this.getAllChapterMemberReportList();
        this.getAllDropdownForOrganizationReportRole();

    }

    getAllChapterMemberReportList = () => {
        this.setState({ isLoading: true });
        let pagination = { ...this.state.pagination };
        this.batchJobExtractionServices.getAllChapterMemberReportList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let details = response.responseItem.responseContent.itemList;
              
                this.setState({
                    organisationRoleReportList: details,
                    totalCount: response.responseItem.responseContent.totalCount,
                })
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }

    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.temp,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };
    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.temp,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };
  handleChangeInDropdown(selected, identity) {
    let temp = { ...this.state.temp }; 
    
    if (identity === "ChapterCategory") {
        const maxSelections = 30;
        const limitedSelections = selected.slice(0, maxSelections); 

        temp.chaptercategoryData = limitedSelections; 

        this.setState({
            temp: temp,
            chaptercategoryData: limitedSelections, 
        }, () => {
            this.validateField("chaptercategoryData"); 
        });
    
    } else if (identity === "status") {
        temp.statusData = selected; 

        this.setState({
            temp: temp,
            statusData: selected, 
        }, () => {
            this.validateField("statusData");
        });
    }
}

    

  getAllDropdownForOrganizationReportRole = () => {
    this.financeServices.getAllDropdownsForOrganisationRoleReports()
        .then(response => {
            if (response.statusCode === 200 && response.responseItem) {
               
                let chapterList = response.responseItem.responseContent.filter(
                    (drp) => drp.dropdownName === "Chapters"
                  );
                 
                //   let statusList = response.responseItem.responseContent.filter(
                //     (drp)=> drp.dropdownName ==="Status"
                //   );

                  chapterList = chapterList.map((p) => ({
                    value: p.id,
                    label: p.name,
                  }));
                //   statusList = statusList.map((p)=>({
                //     value : p.id,
                //     label : p.name
                //   }))
                  this.setState({
                    chapterList: chapterList,
                    // sstatusList : statusList
                  }, () => this.getAllChapterMemberReportList());
            } else {
                this.getAllChapterMemberReportList();
                this.swalServices.Error(response.message);
             
            }
        })
        .catch(error => {
            // Handle any errors that occurred during the request
            console.error("An error occurred while fetching dropdowns:", error);
        });
};
    


    generateChapterMemberReport = () => {
        if (this.state.currentDate) {
            this.setState({ isLoading: true });
            if (this.state.temp.chaptercategoryData.length > 0) {
                var arr1 = this.state.temp.chaptercategoryData.map(function (item) { delete item.bad; return item.value; });
                this.state.pagination.selectedChapterCategory = arr1.join([","]);
              }
              if (this.state.temp.statusData) {
               
                this.state.pagination.statusId = this.state.temp.statusData.value;
              }
           
            let request = {
                currentDate: this.state.currentDate,
                filterByChapters: this.state.pagination.selectedChapterCategory,
                statusId: this.state.pagination.statusId
              
            };
            let isValidateAllFields = this.isValidateAllFields()
            if (isValidateAllFields === true) {
            this.batchJobExtractionServices.generateChapterMemberReport(request).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    this.swalServices.Success("Chapter Member record generated successfully.");
                    // this.getAllChapterMemberReportList();
                }
                else if (response.statusCode === 409) {
                    this.swalServices.Error(response.message);
                }
                else {
                    this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
            });
        }
        }
        else {
            this.swalServices.Alert('Please select date');
        }
    }



    downloadSheetByChapterMemberReportId = (organisationRoleReportId) => {
        this.setState({ isLoading: true });
        let request = [];
        request.push(organisationRoleReportId);
        this.batchJobExtractionServices.downloadSheetByChapterMemberReportId(request).then((response) => {
            if (response.status === 200 && response.data != null) {
                let blob = new Blob([response.data])
                saveAs(blob, `ChapterMemberReport` + ".xlsx");
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'currentDate') {
            this.setState({ currentDate: value })
        }

    }

    render() {
        return (
            <div className="main-body-section finance-body-sec">
                <div className="bg-white">
                    <div className="grid grid-cols-12 gap-6 w-full items-center px-8 py-10">
                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                Date<span className="text-[#C00000]">*</span>
                            </label>
                            <div >
                                <input
                                    id="currentDate"
                                    name="currentDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(this.state.currentDate).format("yyyy-MM-DD")}
                                    onChange={(event) => this.handleChange(event)}
                                    //disabled
                                />
                            </div>
                        </div>
                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                Chapter<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="input">

                                <Selector
                                options={this.state.chapterList}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                // components={{
                                //   Option,
                                // }}
                                onChange={this.handleChangeInDropdown.bind(this)}
                                allowSelectAll={true}
                                //value={this.state.paymentCategory.find(item => item.label === this.state.pagination.paymentCategory)}
                                value={this.state.chaptercategoryData}
                                drpIdentity="ChapterCategory"
                                />
                            </div>
                               <ValidationText error={this.state.validState.error.chaptercategoryData} />
                        </div>
                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                Status<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="input">

                                <Selector
                                options={this.state.statusList}
                                // isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                // components={{
                                //   Option,
                                // }}
                                onChange={this.handleChangeInDropdown.bind(this)}
                                allowSelectAll={false}
                                //value={this.state.paymentCategory.find(item => item.label === this.state.pagination.paymentCategory)}
                                // value={this.state.organisationRoleList.find(
                                //     (item) =>
                                //       item.label === this.state.pagination.organisationRoleStatus
                                //   )}
                                value={this.state.statusData}
                                drpIdentity="status"
                                />
                              
                            </div>
                            <ValidationText error={this.state.validState.error.statusData} />
                        </div>
                       
                        <div className="col-span-4">
                            <button className="btn btn-red text-xl border text-white mt-8" onClick={() => this.generateChapterMemberReport()}>
                                Generate Chapter Members Report
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6 w-full items-center justify-center px-8 py-10">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                            {this.state.isLoading ? <PageLoader /> :
                                <table className="w-full mx-auto my-5 fixed_header batch-job-extraction-table border">
                                    <thead>
                                        <tr className="text-base font-bold flex">
                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                                                <div className="font-bold tble-th-txt text-left">
                                                    Generate Date
                                                </div>
                                            </th>
                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                                                <div className="font-bold tble-th-txt text-left">
                                                    Number of Records
                                                </div>
                                            </th>
                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                                                <div className="font-bold tble-th-txt text-center">Chapter</div>
                                            </th>

                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                                                <div className="font-bold tble-th-txt text-center">Action</div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="scrollit text-sm custom-tbody">
                                        {this.state.organisationRoleReportList.map((e, key) => {
                                            return (
                                                <tr className="custom-tbl-td flex items-center" key={key}>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%] ">
                                                        {moment(e.generationDate).format("DD MMM YYYY")}
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                                        {e.numberOfRecords}
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                                                        {e.chapter ? e.chapter : "-"}
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                                        <button
                                                            className="action-btn mx-auto flex items-center"
                                                            aria-controls="add-edit-modal"
                                                            onClick={() => {
                                                                this.downloadSheetByChapterMemberReportId(e.chapterMemberReportId);
                                                            }}
                                                        >
                                                            <p className="ml-2 tool-tip-txt underline text-[#81a3b8]">
                                                                Download
                                                            </p>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            }
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}
