import moment from "moment";
import React, { Children, Component } from "react";
import Selector from "../../components/dropdown/commonDropdown/Selector";
import BatchJobExtractionServices from "../../services/axiosServices/apiServices/BatchJobExtractionServices";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { saveAs } from "file-saver";
import PageLoader from "../../components/common/pageLoader/PageLoader";
import { CommonValidationMessages } from "../../utils/Messages";
import { validate,isValidForm } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";
import Option from "../../components/dropdown/multiSelectDropdownOption/MultiSelectOption"


export default class OrganisationRoleReport extends Component {
    constructor(props) {
        super(props);
        this.batchJobExtractionServices = new BatchJobExtractionServices();
        this.financeServices = new FinanceServices();

        this.swalServices = new SwalServices();
        this.state = {
            validationRule: {
                membershipcategorydata: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "Membership Category"),
                    },
                ],
                organisationroledata: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "Organisation role"),
                    },
                   
                ],
                chaptercategoryData: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "Chapter"),
                    },
                ],
            },
            temp:
{           chaptercategoryData:[],
            membershipcategorydata:[],
            organisationroledata:[]
}
,            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "CreatedAt",
                orderFlag: 0,
                searchText: "",
                organisationRoleStatus:0,
                selectedChapterCategory: [],
                selectedMembershipCategory: [],
            },
            chapterList: [],
            organisationRoleList:[],
            membershipCategoryList: [],
            isLoading: false,
            currentDate: new Date(),
            organisationRoleReportList: [],
            totalCount: 0,
            validState: {
                isValid: true,
                error: {},
            },
        };
    }

    componentDidMount = () => {
        this.getAllOrganisationRoleReportList();
        this.getAllDropdownForOrganizationReportRole();

    }

    getAllOrganisationRoleReportList = () => {
        this.setState({ isLoading: true });
        let pagination = { ...this.state.pagination };
        this.batchJobExtractionServices.getAllOrganisationRoleReportList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let details = response.responseItem.responseContent.itemList
                this.setState({
                    organisationRoleReportList: details,
                    totalCount: response.responseItem.responseContent.totalCount,
                })
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }


    // handleChangeInDropdown = (selected, drpIdentity) => {
    //     let detail = this.state.paymentProofApproveDetails;
    //     let page = this.state.pagination;
    //     if (drpIdentity === "BankName") {
    //       detail.bankInformationNameId = selected.value;
    //       if (selected.value === 0) {
    //         detail.bankInformationName = "";
    //       } else {
    //         detail.bankInformationName = selected.label;
    //       }
    //       this.setState({ paymentProofApproveDetails: detail });
    //       this.validateField("bankInformationName");
    //     } else if (drpIdentity === "PaymentCategory") {
    //       if (selected.value === 0) {
    //         page.paymentCategory = "Event";
    //       } else {
    //         let selectedPaymentCategory = [];
    //         let commaSeparatedString = "";
    //         selected.forEach((selectedObj, selectedObjIndex) => {
    //           switch (selectedObj.label) {
    //             case "Event":
    //               selectedPaymentCategory.push(4);
    //               break;
    //             case "Membership":
    //               selectedPaymentCategory.push(1);
    //               selectedPaymentCategory.push(3);
    //               break;
    //             case "External Invoice":
    //               selectedPaymentCategory.push(5);
    //               break;
    //             case "Additional Chapter":
    //               selectedPaymentCategory.push(2);
    //               break;
    //             case "DT-SME Package":
    //               selectedPaymentCategory.push(6);
    //               break;
    //             default:
    //               selectedPaymentCategory.push(3);
    //           }
    //         });
    //         if (selectedPaymentCategory.length > 0) {
    //           commaSeparatedString = selectedPaymentCategory.join([","]);
    //         }
    //         page.paymentCategory = commaSeparatedString;
    //         page.selectedPaymentCategory = selected;
    //       }
    //       this.setState({ pagination: page });
    //     } else if (drpIdentity === "PaymentStatus") {
    //       if (selected.value === 0) {
    //         page.reconcilationStatus = "";
    //       } else {
    //         page.reconcilationStatus = selected.label;
    //       }
    //       this.setState({ pagination: page });
    //     }
    //   };

    handleChangeInDropdown(selected, identity) {
        let temp = { ...this.state.temp }; // Create a shallow copy of the temp object
        
        if (identity === "ChapterCategory") {
            const maxSelections = 30;
            const limitedSelections = selected.slice(0, maxSelections); // Limit the selections to 30
    
            temp.chaptercategoryData = limitedSelections; // Update the chaptercategoryData in temp
    
            // Update the state with temp object
            this.setState({
                temp: temp, // Set the updated temp object
                chaptercategoryData: limitedSelections, // Also update chaptercategoryData separately
            }, () => {
                this.validateField("chaptercategoryData"); // Call validation function
            });
        } else if (identity === "MembershipCategory") {
            const maxSelections = 30;
            const limitedSelections = selected.slice(0, maxSelections);
    
            temp.membershipcategorydata = limitedSelections; // Update membershipcategorydata in temp
    
            // Update the state with temp object
            this.setState({
                temp: temp, // Set the updated temp object
                membershipcategorydata: limitedSelections, // Also update membershipcategorydata separately
            }, () => {
                this.validateField("membershipcategorydata");
            });
        } else if (identity === "OrganisationRoleStatus") {
            temp.organisationroledata = selected; // Update organisationroledata in temp
    
            // Update the state with temp object
            this.setState({
                temp: temp, // Set the updated temp object
                organisationroledata: selected, // Also update organisationroledata separately
            }, () => {
                this.validateField("organisationroledata");
            });
        }
    }
    
 
   
  validateField = (key) => {
    const newValidState = validate(
        key,
        this.state.temp,
        this.state.validationRule,
        this.state.validState
    );
    this.setState({ validState: newValidState });
}; 

    getAllDropdownForOrganizationReportRole = () => {
        this.financeServices.getAllDropdownsForOrganisationRoleReports()
            .then(response => {
                if (response.statusCode === 200 && response.responseItem) {
                    // console.log("Response:", response);
                    let chapterList = response.responseItem.responseContent.filter(
                        (drp) => drp.dropdownName === "Chapters"
                      );
                      let membershipCategoryList =
                        response.responseItem.responseContent.filter(
                          (drp) => drp.dropdownName === "MembershipType"
                        );

                      let organisationRoleList = response.responseItem.responseContent.filter(
                        (drp)=> drp.dropdownName ==="OrganisationRoles"
                      );


                      chapterList = chapterList.map((p) => ({
                        value: p.id,
                        label: p.name,
                      }));
                      membershipCategoryList = membershipCategoryList.map((p) => ({
                        value: p.id,
                        label: p.name,
                      }));

                      organisationRoleList = organisationRoleList.map((p)=>({
                        value : p.id,
                        label : p.name
                      }))
                      this.setState({
                        chapterList: chapterList,
                        membershipCategoryList: membershipCategoryList,
                        organisationRoleList : organisationRoleList
              
                      }, () => this.getAllOrganisationRoleReportList());
                } else {
                    this.getAllOrganisationRoleReportList();
                    this.swalServices.Error(response.message);
                 
                }
            })
            .catch(error => {
                // Handle any errors that occurred during the request
                //console.error("An error occurred while fetching dropdowns:", error);
            });
    };
    


    generateOrganisationRoleReport = () => {
        if (this.state.currentDate) {
            this.setState({ isLoading: true });
            if (this.state.temp.chaptercategoryData.length > 0) {
                var arr1 = this.state.temp.chaptercategoryData.map(function (item) { delete item.bad; return item.value; });
                this.state.pagination.selectedChapterCategory = arr1.join([","]);
              }
              if (this.state.temp.membershipcategorydata.length > 0) {
                var arr1 = this.state.temp.membershipcategorydata.map(function (item) { delete item.bad; return item.value; });
                this.state.pagination.selectedMembershipCategory = arr1.join([","]);
              }
              if (this.state.temp.organisationroledata.length > 0) {
               
                this.state.pagination.organisationRoleStatus = this.state.temp.organisationroledata.value
              }
            // let request = {
            //     currentDate: this.state.currentDate
            // };
            let request = {
                currentDate: this.state.currentDate,
                filterByChapters: this.state.pagination.selectedChapterCategory,
                filterByMembershipCategory: this.state.pagination.selectedMembershipCategory,
                filterOrganisationRole: this.state.temp.organisationroledata.value
            };
            let isValidateAllFields = this.isValidateAllFields()
            if (isValidateAllFields === true) {
            this.batchJobExtractionServices.generateOrganisationRoleReport(request).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    this.swalServices.Success("Oraganisation role record generated successfully.");
                    this.getAllOrganisationRoleReportList();
                }
                else if (response.statusCode === 409) {
                    this.swalServices.Error(response.message);
                }
                else {
                    this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
            });
        }
        }
        else {
            this.swalServices.Alert('Please select date');
        }
    }



    downloadSheetByOrganisationRoleReportId = (organisationRoleReportId) => {
        this.setState({ isLoading: true });
        let request = [];
        request.push(organisationRoleReportId);
        this.batchJobExtractionServices.downloadSheetByOrganisationRoleReportId(request).then((response) => {
            if (response.status === 200 && response.data != null) {
                let blob = new Blob([response.data])
                saveAs(blob, `OrganisationRoleReport` + ".xlsx");
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'currentDate') {
            this.setState({ currentDate: value })
        }

    }
    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.temp,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };

    render() {
        return (
            <div className="main-body-section finance-body-sec">
                <div className="bg-white">
                    <div className="grid grid-cols-12 gap-6 w-full items-center px-8 py-10">
                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                Date<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="disabled-input">
                                <input
                                    id="currentDate"
                                    name="currentDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(this.state.currentDate).format("yyyy-MM-DD")}
                                    onChange={(event) => this.handleChange(event)}
                                    //disabled
                                />
                            </div>
                            <ValidationText error={this.state.validState.error.startDate} />
                        </div>
                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                Chapter<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="input">

                                <Selector
                                options={this.state.chapterList}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                // components={{
                                //     Option,
                                //   }}
                                onChange={this.handleChangeInDropdown.bind(this)}
                                allowSelectAll={true}
                                //value={this.state.paymentCategory.find(item => item.label === this.state.pagination.paymentCategory)}
                                value={this.state.temp.chaptercategoryData}
                                drpIdentity="ChapterCategory"
                                />
                                
                            </div>
                            <ValidationText error={this.state.validState.error.chaptercategoryData} />
                        </div>
                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                Membership Category<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="input">

                                <Selector
                                options={this.state.membershipCategoryList}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                // components={{
                                //   Option,
                                // }}
                                onChange={this.handleChangeInDropdown.bind(this)}
                                allowSelectAll={true}
                                // value={this.state.paymentCategory.find(item => item.label === this.state.pagination.paymentCategory)}
                                value={this.state.temp.membershipcategorydata}
                                drpIdentity="MembershipCategory"
                                />
                              
                            </div>
                            <ValidationText error={this.state.validState.error.membershipcategorydata} />
                        </div>

                        <div className="col-span-3">
                            <label
                                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                htmlFor="default"
                            >
                                Orgnaisation Role<span className="text-[#C00000]">*</span>
                            </label>
                            <div className="input">

                                <Selector
                                options={this.state.organisationRoleList}
                                // isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                // components={{
                                //   Option,
                                // }}
                                onChange={this.handleChangeInDropdown.bind(this)}
                                allowSelectAll={false}
                                //value={this.state.paymentCategory.find(item => item.label === this.state.pagination.paymentCategory)}
                                // value={this.state.organisationRoleList.find(
                                //     (item) =>
                                //       item.label === this.state.pagination.organisationRoleStatus
                                //   )}
                                value={this.state.temp.organisationroledata}
                                drpIdentity="OrganisationRoleStatus"
                                />
                              
                            </div>
                            <ValidationText error={this.state.validState.error.organisationroledata} />
                        </div>
                        <div className="col-span-4">
                            <button className="btn btn-red text-xl border text-white mt-8" onClick={() => this.generateOrganisationRoleReport()}>
                                Generate Organisation Role Report
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6 w-full items-center justify-center px-8 py-10">
                        <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
                            {this.state.isLoading ? <PageLoader /> :
                                <table className="w-full mx-auto my-5 fixed_header batch-job-extraction-table border">
                                    <thead>
                                        <tr className="text-base font-bold flex">
                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                                                <div className="font-bold tble-th-txt text-left">
                                                    Generate Date
                                                </div>
                                            </th>
                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                                                <div className="font-bold tble-th-txt text-left">
                                                    Number of Records
                                                </div>
                                            </th>

                                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                                                <div className="font-bold tble-th-txt text-center">Action</div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="scrollit text-sm custom-tbody">
                                        {this.state.organisationRoleReportList.map((e, key) => {
                                            return (
                                                <tr className="custom-tbl-td flex items-center" key={key}>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%] ">
                                                        {moment(e.generationDate).format("DD MMM YYYY")}
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                                                        {e.numberOfRecords}
                                                    </td>

                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                                        <button
                                                            className="action-btn mx-auto flex items-center"
                                                            aria-controls="add-edit-modal"
                                                            onClick={() => {
                                                                this.downloadSheetByOrganisationRoleReportId(e.organisationRoleReportId);
                                                            }}
                                                        >
                                                            <p className="ml-2 tool-tip-txt underline text-[#81a3b8]">
                                                                Download
                                                            </p>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            }
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}
